import React from 'react';
import './TagLine.scss';

export class TagLine extends React.Component {
    render() {
        return <div className="tag-line">
            <img src="OnlyText.png" className="logo" alt="Logo" />
        </div>
    }
}


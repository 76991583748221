import React from 'react';
import './App.scss';
import { Welcome } from './Welcome/Welcome';

export function App() {
    return (
        <Welcome />
    );
}


